<template>
  <v-dialog
    v-model="exibirModalBandeirasCheckinpay"
    width="90vw"
    max-width="1000px"
    persistent
  >
    <Loading v-if="loading" />
    <div
      style="display: flex; flex-direction: column; background-color: white"
      v-if="!loading"
    >
      <v-toolbar
        color="blue"
        style="
          color: white;
          display: flex;
          flex-direction: column;
          padding-top: 5px;
          padding-bottom: 5px;
          width: 100%;
        "
      >
        <div
          style="
            min-width: 100% !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span class="toolbar-title" style="width: 80%; padding: 10px">
            Bandeiras Checkinpay
          </span>
          <span style="width: 5%">
            <v-btn
              x-small
              color="red"
              @click="() => this.$emit('setExibirModalBandeirasCheckinpay')"
              title="Fechar"
            >
              <v-icon x-small style="color: white">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </v-toolbar>
      <v-data-table v-if="!loading" :headers="headers" :items="bandeiras">
        <template v-slot:[`item.descricao_hospede`]="{ item }">
          <input
            class="inputTextEnviarMensagem"
            v-model="item.descricao_hospede"
            type="text"
            placeholder="Descrição Checkinpay"
          />
        </template>

        <template v-slot:[`item.ativo`]="{ item }">
          <v-switch v-model="item.ativo"></v-switch>
        </template>
      </v-data-table>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 100%;
        align-items: flex-end;
        background-color: white;
      "
    >
      <button class="buttonsalvar" @click="salvar">Salvar</button>
    </div>
  </v-dialog>
</template>

<script>
import Loading from "../ConfiguracoesWebCheckin/Loading.vue";
export default {
  props: ["exibirModalBandeirasCheckinpay"],
  components: {
    Loading,
  },
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "Bandeira", value: "descricao" },
        { text: "Descrição Checkinpay", value: "descricao_hospede" },
        { text: "Ativo", value: "ativo" },
      ],
      bandeiras: null,
      loading: true,
      textoBotaoSalvar: "Salvar",
    };
  },
  methods: {
    async requisitarBandeiras() {
      try {
        const result = await this.$http.post("/checkinpay/getBandeiras");
        this.bandeiras = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async salvar() {
      this.loading = true;
      try {
        await this.$http.post("/checkinpay/saveBandeiras", this.bandeiras);
        alert("Bandeiras salva com sucesso");
      } catch (error) {
        alert("Erro ao salvar bandeiras");
      }
      this.loading = false;
    },
  },
  async mounted() {
    await this.requisitarBandeiras();
    this.loading = false;
  },
};
</script>

<style scoped>
.buttonsalvar {
  background-color: #2b81d6;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: small;
  font-weight: 100;
  width: 100px;
}
.inputTextEnviarMensagem {
  background-color: white;
  border: 1px solid rgb(148, 148, 148);
  width: 50%;
  border-radius: 10px;
  padding: 1%;
}
</style>
